/* eslint-disable @next/next/no-img-element */
import styles from './Layout.module.scss'
import classnames from 'classnames'
import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Layout: React.FC = ({ children }) => {
    return (
        <>
            <div className={styles.container}>
                <Header floating={true} />
                <main className={styles.mainContent}>{children}</main>
                <Footer />

                <img
                    src="/images/background-1.png"
                    className={classnames(styles.animatingBackground1, styles.animatingBackground)}
                    alt=""
                />
                <img
                    src="/images/background5.png"
                    className={classnames(styles.animatingBackground2, styles.animatingBackground)}
                    alt=""
                />
                <img
                    src="/images/background-2.png"
                    className={classnames(styles.animatingBackground3, styles.animatingBackground)}
                    alt=""
                />
                <img
                    src="/images/background4.png"
                    className={classnames(styles.animatingBackground4, styles.animatingBackground)}
                    alt=""
                />
                <img
                    src="/images/background-1.png"
                    className={classnames(styles.animatingBackground5, styles.animatingBackground)}
                    alt=""
                />
                <img
                    src="/images/background-1.png"
                    className={classnames(styles.animatingBackground6, styles.animatingBackground)}
                    alt=""
                />
                <img
                    src="/images/background4.png"
                    className={classnames(styles.animatingBackground7, styles.animatingBackground)}
                    alt=""
                />
                <img
                    src="/images/background-2.png"
                    className={classnames(styles.animatingBackground8, styles.animatingBackground)}
                    alt=""
                />
            </div>
            <div className={styles.background1}>
                <img src={'/images/background4.png'} alt="" width={100} />
            </div>
            <div className={styles.background2}>
                <img src={'/images/background-2.png'} alt="" width={100} />
            </div>
            <div className={styles.background} />
        </>
    )
}

export default Layout
