/* eslint-disable @next/next/no-img-element */
import styles from './Footer.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'
import Link from 'next/link'

interface Props {
    className?: Value
}

const Footer: React.FC<Props> = ({ className }) => {
    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.inner}>
                <div className={styles.block}>
                    <Link href={'/'}>
                        <a className={styles.logo}>
                            <img src="/images/logo_name_white.png" alt={'Solice Logo'} />
                        </a>
                    </Link>
                </div>
                <div className={styles.block}>
                    <h4 className={styles.heading}>About</h4>
                    <Link href={'https://docs.solice.io'}>
                        <a className={styles.link}>Documentation</a>
                    </Link>
                    <Link href={'https://docs.solice.io/more/roadmap'}>
                        <a className={styles.link}>Roadmap</a>
                    </Link>
                    <Link href={'https://docs.solice.io/more/team'}>
                        <a className={styles.link}>Team</a>
                    </Link>
                </div>
                <div className={styles.block}>
                    <h4 className={styles.heading}>Solice</h4>
                    <Link href={'https://staking.solice.io'}>
                        <a className={styles.link}>Genesis Staking</a>
                    </Link>
                    <Link href={'https://portal.solice.io'}>
                        <a className={styles.link}>Staking</a>
                    </Link>
                    <Link href={'https://solice.io/play'}>
                        <a className={styles.link}>Play</a>
                    </Link>
                    <Link href={'https://market.solice.io'}>
                        <a className={styles.link}>Marketplace</a>
                    </Link>
                    <Link href={'https://solice.io/map'}>
                        <a className={styles.link}>World map</a>
                    </Link>
                    <a className={styles.link}>
                        Editor <small>(coming soon)</small>
                    </a>
                </div>
                <div className={styles.block}>
                    <h4 className={styles.heading}>Socials</h4>
                    <Link href={'https://t.me/solice_io'}>
                        <a className={styles.link}>Telegram</a>
                    </Link>
                    <Link href={'https://medium.com/@solice_io'}>
                        <a className={styles.link}>Medium</a>
                    </Link>
                    <Link href={'https://discord.gg/hybrRStrKe'}>
                        <a className={styles.link}>Discord</a>
                    </Link>
                    <Link href={'https://twitter.com/solice_io'}>
                        <a className={styles.link}>Twitter</a>
                    </Link>
                    <Link href={'https://github.com/SoliceIO'}>
                        <a className={styles.link}>Github</a>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer
