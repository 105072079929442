import { Connection, PublicKey } from '@solana/web3.js'

import { getAssociatedTokenAddress } from './getAssociatedTokenAddress'

export const getTokenBalance = async (connection: Connection, owner: PublicKey, mint: PublicKey) => {
    try {
        const address = await getAssociatedTokenAddress(mint, owner)
        const result = await connection.getTokenAccountBalance(address)
        return result.value
    } catch (error: unknown) {
        return null
    }
}
