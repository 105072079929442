import { PublicKey } from '@solana/web3.js'

if (!process.env.NEXT_PUBLIC_MINT_ADDRESS) {
    throw new Error('No SLC mint address set')
}

if (!process.env.NEXT_PUBLIC_STAKING_ADDRESS) {
    throw new Error('No destination wallet address set')
}

export const SLC_MINT = new PublicKey(process.env.NEXT_PUBLIC_MINT_ADDRESS)

export const STAKING_WALLET_ADDRESS = new PublicKey(process.env.NEXT_PUBLIC_STAKING_ADDRESS)

export const DECIMALS = 1000000

export const nftSymbols = ['SLCAVT', 'SLCLAND', 'SLCSTAK']
export const nftMultipliers = {
    // use symbol name
    SLCAVT: 0.3,
    // use nft name
    'Genesis - Sapphire Ore': 0.05,
    'Genesis - Sapphire Gemstone': 0.1,
    'Genesis - Sapphire Crystal': 0.1,
    'Genesis - Ruby Crystal': 0.2,
    'Grey Slasher': 0.15,
    'Aqua Oblivion': 0.2,
    Havoc: 0.25,
    Vanguard: 0.4,
    'Building ZT-04': 0.25,
    'Building ZT-03': 0.3,
    'Building ZT-02': 0.35,
    'Building ZT-01': 0.4,
    // find on meta data
    '1x1': 0.1,
    '3x3': 0.25,
}
