import '../styles/globals.scss'
import '@solana/wallet-adapter-react-ui/styles.css'
import 'nprogress/nprogress.css'

import type { AppProps } from 'next/app'
import Layout from '../components/Layout/Layout'
import React, { ReactNode, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { Toaster } from 'react-hot-toast'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import Modal from 'react-modal'
import TokenBalanceProvider from '../components/Wallet/TokenBalanceProvider'
import TagManager from 'react-gtm-module'

Modal.setAppElement('#__next')

NProgress.configure({ showSpinner: false })

export const WalletConnectionProvider = dynamic<{ children: ReactNode }>(
    () =>
        import('../components/Wallet/WalletConnectionProvider').then(
            (WalletConnectionProvider) => WalletConnectionProvider
        ),
    {
        ssr: false,
    }
)

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        TagManager.initialize({
            gtmId: 'GTM-MMKFM2R',
        })
    }, [])

    return (
        <WalletConnectionProvider>
            <TokenBalanceProvider>
                <Layout>
                    <Component {...pageProps} />
                    <Toaster />
                </Layout>
            </TokenBalanceProvider>
        </WalletConnectionProvider>
    )
}

export default MyApp
