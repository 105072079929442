/* eslint-disable @next/next/no-img-element */
import styles from './Header.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'
import Link from 'next/link'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'

interface Props {
    className?: Value
    floating?: boolean
}

const Header: React.FC<Props> = ({ className, floating }) => {
    return (
        <div
            className={classnames(styles.container, className, {
                [styles.isFloating]: floating,
            })}
        >
            <Link href={'/'}>
                <a className={styles.logo}>
                    <img src="/images/logo_name_white.png" alt={'Solice Logo'} />
                </a>
            </Link>
            <nav className={styles.nav}>
                <Link href={'https://solice.io/map'}>
                    <a className={styles.navLink}>Map</a>
                </Link>
                <Link href={'https://market.solice.io'}>
                    <a className={styles.navLink}>Marketplace</a>
                </Link>
                <Link href={'https://staking.solice.io'}>
                    <a className={styles.navLink}>Genesis Staking</a>
                </Link>
                <Link href={'https://portal.solice.io'}>
                    <a className={styles.navLink}>Staking</a>
                </Link>
                <WalletMultiButton />
            </nav>
        </div>
    )
}

export default Header
