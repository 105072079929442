import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import React, { useCallback, useEffect, useState } from 'react'
import { getNFTs } from '../../utils/getNFTs'
import { getTokenBalance } from '../../utils/getTokenBalance'
import { SLC_MINT } from '../../utils/consts'

export const TokenContext = React.createContext<number>(0)

const TokenBalanceProvider: React.FC = ({ children }) => {
    const { connection } = useConnection()
    const { publicKey, connected } = useWallet()
    const [amount, setAmount] = useState<number>(0)

    const getAmount = useCallback(async () => {
        if (!publicKey) return
        const result = await getTokenBalance(connection, publicKey, SLC_MINT)

        if (!result?.uiAmount) {
            setAmount(0)
            return
        }

        setAmount(result.uiAmount)
    }, [connection, publicKey])

    useEffect(() => {
        if (!publicKey || !connected) return

        const interval = window.setInterval(() => {
            getAmount()
        }, 20000)
        getAmount()

        return () => window.clearInterval(interval)
    }, [connected, connection, publicKey, getAmount])

    useEffect(() => {
        if (!connected) setAmount(0)
    }, [connected])

    return <TokenContext.Provider value={amount}>{children}</TokenContext.Provider>
}

export default TokenBalanceProvider
